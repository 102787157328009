import logo from './logo.svg';
import {React,useState} from 'react';
import './App.css';
import * as mdb from 'mdb-ui-kit'; // lib
import { Input } from 'mdb-ui-kit'; // module

import { MDBBadge, MDBBtn, MDBTable, MDBTableHead, MDBTableBody} from 'mdb-react-ui-kit';





function ContactUs() {

  

var CoursesArr = [];




  return (
    <div className="App">
      <header>

      <nav class="navbar fixed-top navbar-expand-lg navbar-light bg-white">
 
        <div class="container">
        
            <button class="navbar-toggler" type="button" data-mdb-toggle="collapse" data-mdb-target="#navbarSupportedContent1" aria-controls="navbarSupportedContent1" aria-expanded="false" aria-label="Toggle navigation">
                <i class="fas fa-bars"></i>
            </button>

          
            <div class="collapse navbar-collapse" id="navbarSupportedContent1">
              
            <a class="navbar-brand mt-2 mt-sm-0" href="https://Hoog.co.il/" >
            <img src="/android-chrome-192x192.png" height="20" alt="Hoog logo" loading="lazy" /><span style={{color:'#0080FF'}}>oog</span>
              
              </a>
              
              <ul class="navbar-nav me-auto mb-2 mb-lg-0" dir="rtl" >
                  <li class="nav-item active">
                      <a class="nav-link" href="/">בית</a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link" href="/aboutus/">אודות</a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link" href="/JoinForm">הצטרפות למאגר</a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link" href="/ContactUs">צור קשר</a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link" href="/Blog">בלוג</a>
                  </li>
              </ul>
              
          </div>
          
          
          <a  href="/" style={{textDecoration:"underline"}}><b>חזרה למאגר החוגים</b></a>
            
          
        </div>

    </nav>




<div  className="p-5 text-center">

</div>

<h5>טופס התקשרות</h5>
</header>
     
<div class="container" style={{paddingTop:"60px",height:"1000px"}}>

<iframe src="https://forms.monday.com/forms/embed/63f83de7478ddfc516cdffae551373a6?r=use1" width="100%" height="1000px" style={{"border": "0; box-shadow: 5px 5px 56px 0px rgba(0,0,0,0.25);"}}></iframe>

</div>



    </div>
   
  );
}

export default ContactUs;
