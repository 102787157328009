import logo from './logo.svg';
import {React,useState} from 'react';
import './App.css';
import * as mdb from 'mdb-ui-kit'; // lib
import { Input } from 'mdb-ui-kit'; // module

import { MDBBadge, MDBBtn, MDBTable, MDBTableHead, MDBTableBody} from 'mdb-react-ui-kit';

import {getFirestore, collection,serverTimestamp , setDoc,doc } from "firebase/firestore"; 


import { app } from './firebase.js';

function AddPost() {

  
const db = getFirestore(app);


{/*   
  categories:_categories,
  ref:_ref,
  totalMeetings:_totalMeetings,
  siteUrl:_siteUrl,
  retailer:_retailer,
  imageCourse:_imageCourse,
*/}

const [postRef, set_postRef] = useState('5782');
const [postTitle, set_postTitle] = useState('ckv c');
const [description, set_description] = useState('');


const [sec_1, set_sec_1] = useState('fffffffffffffffff');
const [sec_2, set_sec_2] = useState('jjjjjjjjjjjjjjjjj');
const [sec_3, set_sec_3] = useState('4444444444444444444444');
const [_imageUrl, set_imageUrl] = useState('https://cdn.pixabay.com/photo/2023/11/07/10/06/girl-8371776_1280.png');


function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }


  
const SendMsg = () => {


  
var data = {
  
    
    imageUrl:_imageUrl,
    description:description,
    ref:postRef,
    title:postTitle,
    sec_1:sec_1,
    sec_2,sec_2,
    sec_3,sec_3,
    dateSend:serverTimestamp(),
    relatedCourses:[]
};

    
    AddUser(data);
    
  
    
    }

async function AddUser(_UserInfo) {    

       

    var UNIQ_key = makeid(10);
    setDoc(doc(db, "posts", UNIQ_key),_UserInfo ).then(docRef => {
      console.log("Document has been added successfully");

       
           
        
        
        })
        .catch(error => {
            console.log(error);
        });
        
        
                 
         }
        
var CoursesArr = [];





  //AddUser(data);

  return (
    <div className="App">
      <header>

      <nav class="navbar fixed-top navbar-expand-lg navbar-light bg-white">
 
        <div class="container">
        
            <button class="navbar-toggler" type="button" data-mdb-toggle="collapse" data-mdb-target="#navbarSupportedContent1" aria-controls="navbarSupportedContent1" aria-expanded="false" aria-label="Toggle navigation">
                <i class="fas fa-bars"></i>
            </button>

          
            <div class="collapse navbar-collapse" id="navbarSupportedContent1">
              
            <a class="navbar-brand mt-2 mt-sm-0" href="https://Hoog.co.il/" >
            <img src="/android-chrome-192x192.png" height="20" alt="Hoog logo" loading="lazy" /><span style={{color:'#0080FF'}}>oog</span>
              
              </a>
              
              <ul class="navbar-nav me-auto mb-2 mb-lg-0" dir="rtl" >
                  <li class="nav-item active">
                      <a class="nav-link" href="/">בית</a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link" href="/aboutus/">אודות</a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link" href="/JoinForm">הצטרפות למאגר</a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link" href="/ContactUs">צור קשר</a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link" href="/Blog">בלוג</a>
                  </li>
              </ul>
              
          </div>
          
          
          <a  href="/" style={{textDecoration:"underline"}}><b>חזרה למאגר החוגים</b></a>
            
          
        </div>

    </nav>




<div  className="p-5 text-center">

</div>

<h5>חוג חדש</h5>
</header>
     
<div class="container" style={{padding:"40px"}} dir="ltr">





<div class="input-group input-group-lg">
  <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"  onChange={(e) => set_postRef(e.target.value)} value={postRef}/>
  <span class="input-group-text" id="inputGroup-sizing-lg">REF</span>
</div>
<br></br>

<div class="input-group input-group-lg">
 
  <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"  onChange={(e) => set_postTitle(e.target.value)} value={postTitle}/>
  <span class="input-group-text" id="inputGroup-sizing-lg">כותרת</span>
</div>

<br></br>
<div class="input-group input-group-lg">
 
  <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"  onChange={(e) => set_description(e.target.value)} value={description}/>
  <span class="input-group-text" id="inputGroup-sizing-lg">תיאור</span>
</div>



<br></br>

<div class="input-group input-group-lg">
 
  <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"  onChange={(e) => set_imageUrl(e.target.value)} value={_imageUrl}/>
  <span class="input-group-text" id="inputGroup-sizing-lg">תמונה</span>

</div>


<br></br>


<div class="input-group input-group-lg">
 
  <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"  onChange={(e) => set_sec_1(e.target.value)} value={sec_1}/>
  <span class="input-group-text" id="inputGroup-sizing-lg">פסקה 1</span>
</div>



<br></br>

<div class="input-group input-group-lg">
 
  <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"  onChange={(e) => set_sec_2(e.target.value)} value={sec_2}/>
  <span class="input-group-text" id="inputGroup-sizing-lg">פסקה 2</span>
</div>




<br></br>

<div class="input-group input-group-lg">
 
  <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"  onChange={(e) => set_sec_3(e.target.value)} value={sec_3}/>
  <span class="input-group-text" id="inputGroup-sizing-lg">פסקה 3</span>
</div>
















<br></br>
<button type="button" class="btn btn-primary"  onClick={SendMsg} style={{width:"300px",height:"50px"}}>Save</button>


</div>
    </div>
   
  );
}

export default AddPost;
