import logo from './logo.svg';
import {React,useState} from 'react';
import './App.css';
import * as mdb from 'mdb-ui-kit'; // lib
import { Input } from 'mdb-ui-kit'; // module

import { MDBBadge, MDBBtn, MDBTable, MDBTableHead, MDBTableBody} from 'mdb-react-ui-kit';


import { BrowserRouter as Router, Routes, Route, Form } from "react-router-dom";

import MainPage from './MainPage';


import ItemPage from './ItemPage';

import AboutUs from './AboutUs';
import JoinForm from './JoinForm';

import ContactUs from './ContactUs';

import BlogItem from './BlogItem';


import Blogs from './Blogs';

import AddCourse from './AddCourse';


import AddPost from './AddPost';


function App() {

  const [isLoading, setisLoading] = useState(false);

  const [age, setage] = useState('התאמה לגיל');

  const [location, setlocation] = useState('איזור גיאוגרפי');
  const [course, setcourse] = useState('חוג ספציפי');  



  function makeSearch(){

  
    setisLoading(true)
    setTimeout( function(){  setisLoading(false) }  ,500);
  }




return (
  <>
   
    <Router>
      <Routes>
       
      
        <Route path="/" element={<MainPage/>} />
        
        <Route path="/crs/:ref" element={<ItemPage/>} />
      
        <Route path="/crs/:ref/:age/:place" element={<ItemPage/>} />
        <Route path="/AboutUs" element={<AboutUs/>} />

        <Route path="/JoinForm" element={<JoinForm/>} />
        
        <Route path="/ContactUs" element={<ContactUs/>} />

        <Route path="/BlogItem/:ref" element={<BlogItem/>} />

        <Route path="/Blog" element={<Blogs/>} />

        <Route path="/AddCourse" element={<AddCourse/>} />

        <Route path="/AddPost" element={<AddPost/>} />
        

      </Routes>
    </Router>
  </>
);
}

export default App;
