import logo from './logo.svg';
import {React,useState,useEffect } from 'react';
import './App.css';
import * as mdb from 'mdb-ui-kit'; // lib
import { Input } from 'mdb-ui-kit'; // module
import { useParams } from 'react-router';
import { MDBBadge, MDBBtn, MDBTable, MDBTableHead, MDBTableBody} from 'mdb-react-ui-kit';
import Image from 'react-bootstrap/Image';

import * as myConstClass from './consts.js';
import {getCources_TEST,getPosts_TEST,filteredList_VISIO,listArrCategories,listArrPlaces,listCourcesSelection} from './firebase'



import {

  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';


import {MDBRow,MDBCol,MDBCard,MDBCardBody,MDBCardTitle,MDBCardText}  from 'mdb-react-ui-kit';

import { getFirestore, collection, addDoc,getDoc,doc, DocumentSnapshot,getDocs,serverTimestamp} from "firebase/firestore";

import { app } from './firebase.js';

import {courcesList} from './dataConstantsCources.js'
import {postList} from './dataConstantsPosts.js'

var arrAgesRange = myConstClass.ArrAges;

var FullCoursesArr = [];


var addItem = false;

var tmp_ArrRelatedCourses = [];

var INNER_filteredList = [];

var arrcources = [];
var arrposts = [];
var tmp_currPost;






function BlogItem() {
  const params= useParams();
 
 

  const result = courcesList;
    // setisLoading(false);
  
    result.forEach(cource => {
    
      var element = cource;

      arrcources.push(element);
    });



    const result2 = postList;
    
    result2.forEach(post => {
      var element = post;

      arrposts.push(element);

    });
  



    
    tmp_currPost = arrposts.find(el => el.ref === params.ref);
      

   if(tmp_currPost)
    tmp_currPost.relatedCourses.forEach((doc) => {

  
       var element =  arrcources.find(el => el.uid === doc);
       console.log(element);
      if(element)
          tmp_ArrRelatedCourses.push( element);
    
    })




  const [isLoading, setisLoading] = useState(false);

  const [openModal, setopenModal] = useState(false);

  const [basicModal, setBasicModal] = useState(false);


  const toggleOpen = () => setBasicModal(!basicModal);

  const [_arrPOsts, set_arrPOsts] = useState(arrposts);
  const [ArrRelatedCourses, setArrRelatedCourses] = useState(tmp_ArrRelatedCourses);
  
  const [CoursesArr, setCoursesArr] = useState(arrcources);

  const [currPost, setcurrPost] = useState(tmp_currPost);
  


const [modalInfo, setmodalInfo] = useState(0);



{/*   
  useEffect(() => {
    const fetchData = async () => {
      const result = await getCources_TEST();

    // setisLoading(false);
  
    result.forEach(quarySnapShot => {
    
      var element = quarySnapShot.data();

      arrcources.push(element);
      
      console.log(element.name);
      


  
  });
    
  
    fetchPosts();
    };
    
    
    fetchData();


    

    const fetchPosts = async () => {

   
      const result = await getPosts_TEST();
    
      result.forEach(quarySnapShot => {
        var element = quarySnapShot.data();

        arrposts.push(element);

      });
    

  
       tmp_currPost = arrposts.find(el => el.ref === params.ref);
      

    
      tmp_currPost.relatedCourses.forEach((doc) => {
 
    
         var element =  arrcources.find(el => el.uid === doc);
         console.log(element);
        if(element)
            tmp_ArrRelatedCourses.push( element);
      
      })

      setcurrPost(tmp_currPost);
      setArrRelatedCourses(tmp_ArrRelatedCourses);
       set_arrPOsts(arrposts);
      


     
    };
  


    

  }, []);
*/}


  return (
    <div className="App">
      <header>

      <nav class="navbar fixed-top navbar-expand-lg navbar-light bg-white">
 
        <div class="container">
        
            <button class="navbar-toggler" type="button" data-mdb-toggle="collapse" data-mdb-target="#navbarSupportedContent1" aria-controls="navbarSupportedContent1" aria-expanded="false" aria-label="Toggle navigation">
                <i class="fas fa-bars"></i>
            </button>

          
            <div class="collapse navbar-collapse" id="navbarSupportedContent1">
              
                <a class="navbar-brand mt-2 mt-sm-0" href="https://Hoog.co.il/" >
                 
                <img src="/android-chrome-192x192.png" height="20" alt="Hoog logo" loading="lazy" /><span style={{color:'#0080FF'}}>oog</span>
                </a>
                
                <ul class="navbar-nav me-auto mb-2 mb-lg-0" dir="rtl" >
                    <li class="nav-item active">
                        <a class="nav-link" href="/">בית</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/aboutus/">אודות</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/JoinForm">הצטרפות למאגר</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/ContactUs">צור קשר</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="/Blog">בלוג</a>
                  </li>
                </ul>
                
            </div>
            
            
            <a  href="/" style={{textDecoration:"underline"}}><b>חזרה למאגר החוגים</b></a>
        </div>

    </nav>



   

</header>

{currPost &&
<div class="container" style={{paddingTop:"100px",textAlign:"right",width:"90%"}}>
<h1>{currPost.title}</h1>


<hr></hr>

<img src={currPost.imageUrl} class="float-end" style={{maxHeight:"150px",maxWidth:"150px",margin:"25px"}}/>


<h5 style={{padding:"25px",textAlign:"right"}} dir="rtl" >
  {currPost.sec_1}
</h5>

<br></br>


<h5 style={{padding:"25px",textAlign:"right"}} dir="rtl" >
  {currPost.sec_2}
</h5>



<br></br>


<h5 style={{padding:"25px",textAlign:"right"}} dir="rtl" >
  {currPost.sec_3}
</h5>



</div>}




{ArrRelatedCourses.length> 0 &&<div class="container" style={{paddingTop:"60px",textAlign:"right",width:"80%"}}>
<h5 style={{padding:"25px",textAlign:"right"}} dir="rtl" > 
קורסים מקושרים
</h5>

<div class="row justify-content-end row-cols-2 row-cols-md-4 g-4" >
  { ArrRelatedCourses.map(function(element,index){
 
  return <a class="col"  href={`/crs/${element.ref}`} >
   <div class="card h-100">
     <img src={element.imageUrl} class="card-img-top" alt="Skyscrapers" style={{maxHeight:"205px"}}/>
    
     <div class="card-body">
       <h5 class="card-title">{element.name}</h5>
      {false &&  <p class="card-text" dir="rtl">
       {element.desc}
       </p>}
     </div>
     {false && <div class="card-footer">
    
     <a  href={`/crs/${element.ref}`}  class="btn btn-link">הצג פרטים</a>
          <button type="button" class="btn btn-link" data-mdb-toggle="modal" data-mdb-target="#exampleModal">
         הצג פרטים
          </button>
     </div>}
   </div>
 </a>
  })}
  


</div>

</div>}

<div class="container" style={{paddingTop:"60px",textAlign:"right",width:"80%"}}>
<h5 style={{padding:"25px",textAlign:"right"}} dir="rtl" > 
אולי יעניין אותך גם...
</h5>

<hr></hr>


  

<MDBRow className="row justify-content-end">



{_arrPOsts.map(function(element, index){
  if( element.ref != params.ref)
     return <MDBCol sm='6' className='p-2 text-right'>
        <MDBCard>
          <MDBCardBody>
            <MDBCardTitle style={{color: "var(--mdb-link-color)"}} dir="rtl">{element.title}</MDBCardTitle>
            <MDBCardText>
            {element.description}
            </MDBCardText>
            <MDBBtn href={`/BlogItem/${element.ref}`}    >קרא עוד</MDBBtn>

          </MDBCardBody>
        </MDBCard>
      </MDBCol>})}
  
     
    </MDBRow>




</div>


    </div>
   
  );
}

export default BlogItem;
