import logo from './logo.svg';
import {React,useState} from 'react';
import './App.css';
import * as mdb from 'mdb-ui-kit'; // lib
import { Input } from 'mdb-ui-kit'; // module

import { MDBBadge, MDBBtn, MDBTable, MDBTableHead, MDBTableBody} from 'mdb-react-ui-kit';

import {getFirestore, collection,serverTimestamp , setDoc,doc } from "firebase/firestore"; 


import { app } from './firebase.js';

function AddCourse() {

  
const db = getFirestore(app);


{/*   
  categories:_categories,
  ref:_ref,
  totalMeetings:_totalMeetings,
  siteUrl:_siteUrl,
  retailer:_retailer,
  imageCourse:_imageCourse,
*/}

const [_name, set_name] = useState('');
const [_price, set_price] = useState('');
const [_desc, set_desc] = useState('');
const [_imageUrl, set_imageUrl] = useState('');
const [_ages, set_ages] = useState('');

const [_places, set_places] = useState('');
const [_address, set_address] = useState('');
const [_phone, set_phone] = useState('');
const [_email, set_email] = useState('');
const [_days, set_days] = useState('');



const [_categories, set_categories] = useState('');
const [_ref, set_ref] = useState('');
const [_totalMeetings, set_totalMeetings] = useState('');
const [_siteUrl, set_siteUrl] = useState('');
const [_retailer, set_retailer] = useState('');



const [_imageCourse, set_imageCourse] = useState('');


function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }


  
const SendMsg = () => {


  
var data = {
  
    name:_name,
    price:_price,
    desc:_desc,
    imageUrl:_imageUrl,
    ages:_ages.split(','),
    places:_places.split(','),
    address:_address,
    phone:_phone,
    email:_email,
    days:_days.split(','),
    categories:_categories.split(','),
    ref:_ref,
    totalMeetings:_totalMeetings,
    siteUrl:_siteUrl,
    retailer:_retailer,
    imageCourse:_imageCourse,
    dateSend:serverTimestamp(),
    uid:makeid(8)
};

    
    AddUser(data);
    
  
    
    }

async function AddUser(_UserInfo) {    

       

    var UNIQ_key = makeid(10);
    setDoc(doc(db, "courses", UNIQ_key),_UserInfo ).then(docRef => {
      console.log("Document has been added successfully");

       
           
        
        
        })
        .catch(error => {
            console.log(error);
        });
        
        
                 
         }
        
var CoursesArr = [];





  //AddUser(data);

  return (
    <div className="App">
      <header>

      <nav class="navbar fixed-top navbar-expand-lg navbar-light bg-white">
 
        <div class="container">
        
            <button class="navbar-toggler" type="button" data-mdb-toggle="collapse" data-mdb-target="#navbarSupportedContent1" aria-controls="navbarSupportedContent1" aria-expanded="false" aria-label="Toggle navigation">
                <i class="fas fa-bars"></i>
            </button>

          
            <div class="collapse navbar-collapse" id="navbarSupportedContent1">
              
            <a class="navbar-brand mt-2 mt-sm-0" href="https://Hoog.co.il/" >
              <img src="/android-chrome-192x192.png" height="20" alt="Hoog logo" loading="lazy" /><span style={{color:'#0080FF'}}>oog</span>
              
              </a>
              
              <ul class="navbar-nav me-auto mb-2 mb-lg-0" dir="rtl" >
                  <li class="nav-item active">
                      <a class="nav-link" href="/">בית</a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link" href="/aboutus/">אודות</a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link" href="/JoinForm">הצטרפות למאגר</a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link" href="/ContactUs">צור קשר</a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link" href="/Blog">בלוג</a>
                  </li>
              </ul>
              
          </div>
          
          
          <a  href="/" style={{textDecoration:"underline"}}><b>חזרה למאגר החוגים</b></a>
            
          
        </div>

    </nav>




<div  className="p-5 text-center">

</div>

<h5>חוג חדש</h5>
</header>
     
<div class="container" style={{padding:"40px"}} dir="ltr">




<div class="input-group input-group-lg">
 
  <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"  onChange={(e) => set_name(e.target.value)} value={_name}/>
  <span class="input-group-text" id="inputGroup-sizing-lg">שם חוג</span>
</div>


<br></br>

<div class="input-group input-group-lg">
 
  <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"  onChange={(e) => set_desc(e.target.value)} value={_desc}/>
  <span class="input-group-text" id="inputGroup-sizing-lg">תיאור קצר</span>
</div>


<br></br>

<div class="input-group input-group-lg">
 
  <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"  onChange={(e) => set_imageUrl(e.target.value)} value={_imageUrl}/>
  <span class="input-group-text" id="inputGroup-sizing-lg">תמונת חוג ראשית</span>

</div>

<br></br>

<div class="input-group input-group-lg">
  
  <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"  onChange={(e) => set_price(e.target.value)} value={_price}/>
  <span class="input-group-text" id="inputGroup-sizing-lg">מחיר</span>
</div>



<br></br>

<div class="input-group input-group-lg">
  
  <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"  onChange={(e) => set_ages(e.target.value)} value={_ages}/>

  <span class="input-group-text" id="inputGroup-sizing-lg">גילאים</span>
</div>



<br></br>

<div class="input-group input-group-lg">
  <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"  onChange={(e) => set_places(e.target.value)} value={_places}/>
  <span class="input-group-text" id="inputGroup-sizing-lg">ערים</span>
</div>


<br></br>

<div class="input-group input-group-lg">
  
  <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"  onChange={(e) => set_address(e.target.value)} value={_address}/>
  <span class="input-group-text" id="inputGroup-sizing-lg">כתובת</span>
</div>


<br></br>

<div class="input-group input-group-lg">
 
  <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"  onChange={(e) => set_phone(e.target.value)} value={_phone}/>
  <span class="input-group-text" id="inputGroup-sizing-lg">טלפון</span>
</div>



<br></br>

<div class="input-group input-group-lg">
  <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"  onChange={(e) => set_email(e.target.value)} value={_email}/>
  <span class="input-group-text" id="inputGroup-sizing-lg">מייל</span>
</div>


<br></br>

<div class="input-group input-group-lg">
  <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"  onChange={(e) => set_days(e.target.value)} value={_days}/>
  <span class="input-group-text" id="inputGroup-sizing-lg">ימים</span>
</div>



<br></br>

<div class="input-group input-group-lg">
  <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"  onChange={(e) => set_categories(e.target.value)} value={_categories}/>
  <span class="input-group-text" id="inputGroup-sizing-lg">קטגוריות</span>
</div>


<br></br>

<div class="input-group input-group-lg">
  <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"  onChange={(e) => set_ref(e.target.value)} value={_ref}/>
  <span class="input-group-text" id="inputGroup-sizing-lg">REF</span>
</div>


<br></br>

<div class="input-group input-group-lg">
  <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"  onChange={(e) => set_totalMeetings(e.target.value)} value={_totalMeetings}/>
  <span class="input-group-text" id="inputGroup-sizing-lg">סה"כ מפגשים</span>
</div>




<br></br>

<div class="input-group input-group-lg">
  <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"  onChange={(e) => set_siteUrl(e.target.value)} value={_siteUrl}/>
  <span class="input-group-text" id="inputGroup-sizing-lg">קישור לאתר</span>
</div>





<br></br>

<div class="input-group input-group-lg">
  <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"  onChange={(e) => set_retailer(e.target.value)} value={_retailer}/>
  <span class="input-group-text" id="inputGroup-sizing-lg">שם העסק</span>
</div>



<br></br>

<div class="input-group input-group-lg">
  <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"  onChange={(e) => set_imageCourse(e.target.value)} value={_imageCourse}/>
  <span class="input-group-text" id="inputGroup-sizing-lg">תמונה של העסק</span>
</div>

<br></br>
<button type="button" class="btn btn-primary"  onClick={SendMsg} style={{width:"300px",height:"50px"}}>Save</button>


</div>
    </div>
   
  );
}

export default AddCourse;
