import logo from './logo.svg';
import {React,useState,useEffect} from 'react';
import './App.css';
import * as mdb from 'mdb-ui-kit'; // lib
import { Input } from 'mdb-ui-kit'; // module

import { MDBBadge, MDBBtn, MDBTable, MDBTableHead, MDBTableBody} from 'mdb-react-ui-kit';

import * as myConstClass from './consts.js';

import {MDBRow,MDBCol,MDBCard,MDBCardBody,MDBCardTitle,MDBCardText}  from 'mdb-react-ui-kit';

import {getCources_TEST,getPosts_TEST} from './firebase'

//import axios from 'axios';

import {courcesList} from './dataConstantsCources.js'
import {postList} from './dataConstantsPosts.js'

function MainPage() {

  const [isLoading, setisLoading] = useState(false);


  
  var itemsCOUntForPage = 0;
  var _arrCounters = [];


  var filteredList_VISIO =[];
  var tmp_listArrCategories = [];
  var  Tmp_listArrPlaces=[];
  var tmp_listCourcesSelection = [];
  var filteredList = [];
  var arrAgesRange = myConstClass.ArrAges;
 

  var arrcources=[];

  courcesList.forEach(course => {
  
   

    var element = course;

    arrcources.push(element);
    
    
    
if(element.places)
{
 
    element.places.forEach(place => {
            const found = Tmp_listArrPlaces.some(el => el.name === place);
            if (!found && place != 'כל הארץ') 
            {
              Tmp_listArrPlaces.push({name:place,val:Tmp_listArrPlaces.length+1});

            }
          
        });
}

var foundCourceName = tmp_listCourcesSelection.some(el => el.name === element.name);
if (!foundCourceName) 
{
  tmp_listCourcesSelection.push({name:element.name,val:tmp_listCourcesSelection.length+1});

}


if(element.categories)
{

  element.categories.forEach(category => {
 
    const found = tmp_listArrCategories.some(el => el.name === category);
    if (!found) tmp_listArrCategories.push({name:category});
  
  });
}
else
{
  let toString = obj => Object.entries(obj).map(([k, v]) => `${k}: ${v}`).join(', ');
  console.log('ERRE:' + toString( element));


}
   
var addItem = false;
var found = false
  var filter1 = true;
  var filter2 = true;
  var filter3 = true;
  var filter4 = false;
 
  addItem = false;
   found = false;
 


   filteredList.push(element);
  
   const found2 = filteredList_VISIO.some(el => el.ref === element.ref);
   
   if(!found2)
     filteredList_VISIO.push(element);
 
   
});


  const [age, setage] = useState('התאמה לגיל');
  const [ageVal, setageVal] = useState(0);

  const [location, setlocation] = useState('איזור גיאוגרפי');
  const [locationVal, setlocationVal] = useState(0);

  const [course, setcourse] = useState('חוג ספציפי');  
  const [category, setcategory] = useState('קטגוריה');  


  const [Paging, setPaging] = useState(8); 

 
  const [CoursesArr, setCoursesArr] = useState(filteredList_VISIO); 
 

  const [listArrPlaces, setlistArrPlaces] = useState(Tmp_listArrPlaces); 
  const [listArrCategories, setlistArrCategories] = useState(tmp_listArrCategories); 
  const [listCourcesSelection, setlistCourcesSelection] = useState(tmp_listCourcesSelection); 
    
  

  const [FullCources, setFullCources] = useState(arrcources);  

  //  setlistCourcesSelection(tmp_listCourcesSelection);
  //  setlistArrPlaces(Tmp_listArrPlaces);
  //  setlistArrCategories(tmp_listArrCategories);
  //  setCoursesArr(filteredList_VISIO);
  //  setFullCources(arrcources);
    _arrCounters = [];
    _arrCounters= getcounters(filteredList);
    
    const [Canounter, setCanounter] = useState(_arrCounters);


  /// HANDLE POSTS LIST ----




 const [ArrPOsts, setArrPOsts] = useState(postList); 


    

{/*   
 useEffect(() => {
    const fetchData = async () => {
    const result = await getCources_TEST();

     
     // setData(result);
     setisLoading(false);
  
    // listArrCategories set(['asdaas'])
    var arrcources=[];
    result.forEach(quarySnapShot => {
    
     

      var element = quarySnapShot.data();

      arrcources.push(element);
      
      
      
  if(element.places)
  {
   
      element.places.forEach(place => {
              const found = Tmp_listArrPlaces.some(el => el.name === place);
              if (!found && place != 'כל הארץ') 
              {
                Tmp_listArrPlaces.push({name:place,val:Tmp_listArrPlaces.length+1});
  
              }
            
          });
  }
  
  var foundCourceName = tmp_listCourcesSelection.some(el => el.name === element.name);
  if (!foundCourceName) 
  {
    tmp_listCourcesSelection.push({name:element.name,val:tmp_listCourcesSelection.length+1});
  
  }
  
  
  if(element.categories)
  {
  
    element.categories.forEach(category => {
   
      const found = tmp_listArrCategories.some(el => el.name === category);
      if (!found) tmp_listArrCategories.push({name:category});
    
    });
  }
  else
  {
    let toString = obj => Object.entries(obj).map(([k, v]) => `${k}: ${v}`).join(', ');
    console.log('ERRE:' + toString( element));
  
  
  }
     
  var addItem = false;
var found = false
    var filter1 = true;
    var filter2 = true;
    var filter3 = true;
    var filter4 = false;
   
   addItem = false;
     found = false;
   
 
   filter1 = (course == element.name) ||  (course == 'חוג ספציפי');

// age 'התאמה לגיל'
         
    

   filter2 = (element.categories.some(el => el === category) || category == 'קטגוריה')
   

   filter3 = (element.places.some(el => el === location  || el === 'כל הארץ') ||  location == 'איזור גיאוגרפי' )
       
 
   if(ageVal>0)
   {
     filter4 = false;
var ageItem = arrAgesRange.find(el => el.val === ageVal);


for(var i=0;i<ageItem.grades.length;i++)
{


 for(var j=0;j<element.ages.length;j++)
 {


  

     if(element.ages[j] == ageItem.grades[i])
     {
       filter4 = true;
       break;
     }
        

 }

 if(filter4)
   break;

}
 }
 else
 filter4 = true;

 //filter4 = true;

   if(filter1 && filter2 && filter3 && filter4)
   {
     filteredList.push(element);
    
     const found = filteredList_VISIO.some(el => el.ref === element.ref);
     
     if(!found)
       filteredList_VISIO.push(element);
   }
      
   

  
  });

      setlistCourcesSelection(tmp_listCourcesSelection);
      setlistArrPlaces(Tmp_listArrPlaces);
      setlistArrCategories(tmp_listArrCategories);
      setCoursesArr(filteredList_VISIO);
      setFullCources(arrcources);
      _arrCounters = [];
      _arrCounters= getcounters(filteredList);
      setCanounter(_arrCounters);
    
console.log(arrcources);
     
    };
    fetchData();

    const fetchPosts = async () => {

      var arrposts = [];

      const result = await getPosts_TEST();
    
      result.forEach(quarySnapShot => {
        var element = quarySnapShot.data();

        arrposts.push(element);

      });
    
     setArrPOsts(arrposts);

     console.log(arrposts);
     
    };
    fetchPosts();


    

  }, []);
 */}


function ShowMore()
{
  setPaging(Paging + 8);
}




function getcounters(arrItems){


  var counts = arrItems.reduce((p, c) => {
    var name = c.ref;
    if (!p.hasOwnProperty(name)) {
      p[name] = 0;
    }
    p[name]++;
    return p;
  }, {});
  
 
  
  var countsExtended = Object.keys(counts).map(k => {
    return {ref: k, count: counts[k]}; });
  
 

  return countsExtended;
}

  function makeSearch(){
    
    var filteredList = [];
    filteredList_VISIO = [];
    var addItem = false;

    var filter1 = true;
    var filter2 = true;
    var filter3 = true;
    var filter4 = false;

    setPaging(8);

   

    FullCources.forEach(element => {
      
      addItem = false;
      var  found = false;
      
    
      filter1 = (course == element.name) ||  (course == 'חוג ספציפי');

// age 'התאמה לגיל'
            
       

      filter2 = (element.categories.some(el => el === category) || category == 'קטגוריה')
      

      filter3 = (element.places.some(el => el === location  || el === 'כל הארץ') ||  location == 'איזור גיאוגרפי' )
          
    
      if(ageVal>0)
      {
        filter4 = false;
var ageItem = arrAgesRange.find(el => el.val === ageVal);


for(var i=0;i<ageItem.grades.length;i++)
{


    for(var j=0;j<element.ages.length;j++)
    {


     

        if(element.ages[j] == ageItem.grades[i])
        {
          filter4 = true;
          break;
        }
           

    }

    if(filter4)
      break;

  }
    }
    else
    filter4 = true;

    //filter4 = true;

      if(filter1 && filter2 && filter3 && filter4)
      {
        filteredList.push(element);
       
        const found = filteredList_VISIO.some(el => el.ref === element.ref);
        
        if(!found)
          filteredList_VISIO.push(element);
      }
         
      
      });
      
      _arrCounters = [];
     _arrCounters= getcounters(filteredList);

     setCanounter(_arrCounters);

    setCoursesArr(filteredList_VISIO);



    setisLoading(false);
    // setTimeout( function(){  setisLoading(false) }  ,500);



  }




var listArrCources = listCourcesSelection; 


  return (


    <div className="App">
      
      <nav class="navbar fixed-top navbar-expand-lg navbar-light bg-white">
 
 <div class="container">
 
     <button class="navbar-toggler" type="button" data-mdb-toggle="collapse" data-mdb-target="#navbarSupportedContent1" aria-controls="navbarSupportedContent1" aria-expanded="false" aria-label="Toggle navigation">
         <i class="fas fa-bars"></i>
     </button>

   
     <div class="collapse navbar-collapse" id="navbarSupportedContent1">
       
     <a class="navbar-brand mt-2 mt-sm-0" href="https://Hoog.co.il/" >
     {false && <img src="/hOOG_logo.png" height="20" alt="Hoog logo" loading="lazy" />}
              
              </a>
        
         <ul class="navbar-nav me-auto mb-2 mb-lg-0" dir="rtl" >
             <li class="nav-item active">
                 <a class="nav-link" href="/">בית</a>
             </li>
             <li class="nav-item">
                 <a class="nav-link" href="/aboutus">אודות</a>
             </li>
             <li class="nav-item">
                 <a class="nav-link" href="/JoinForm">הצטרפות למאגר</a>
             </li>
             <li class="nav-item">
                 <a class="nav-link" href="/ContactUs">צור קשר</a>
             </li>
             <li class="nav-item">
                      <a class="nav-link" href="/Blog">בלוג</a>
                  </li>
         </ul>
        
     </div>
     
     <div class="d-flex align-items-center">
        
       </div>
  
 </div>

</nav>



     
<div class="container" style={{paddingTop:"80px"}}>

<img   src={require('./images/HOOG_logo.png')}  height="50" alt="Hoog logo" loading="lazy" />
{false && <img src={require('./images/Hoog-logo2.png')} style={{maxHeight:"30px"}} ></img>}
<h5 dir="rtl" style={{padding:"10px"}}>כל החוגים במקום אחד</h5>
{false && <div style={{display: "flex",justifyContent:"center",alignItems:"center"}} >
<div class="form-outline"  style={{width:"300px",textAlign:"center"}} >
  <input type="text" id="form12" class="form-control" />
  <label class="form-label" for="form12">חיפוש חופשי</label>
</div>
</div>}


<div style={{display: "flex",justifyContent:"center",alignItems:"center"}} >

<div class="row row-cols-lg-auto g-3 align-items-center" style={{paddingTop:"20px",paddingBottom:"10px"}}>
<div class="col-6">
  <div class="dropdown" style={{width:"100%"}}>
  <button style={{width:"100%"}}
    class="btn btn-primary dropdown-toggle"
    type="button"
    id="dropdownMenuButton"
    data-mdb-toggle="dropdown"
    aria-expanded="false"
  dir="rtl" >
 {age}
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">

  { arrAgesRange.map(function(element){
     return  <li><a class="dropdown-item"   onClick={(e) => {setage(element.name);setageVal(element.val);}} dir="rtl"    >{element.name}</a></li>
     
                  })}

  

   
  </ul>
</div>
  </div>
  <div class="col-6">
  <div class="dropdown" style={{width:"100%"}}>
  <button style={{width:"100%"}}
    class="btn btn-primary dropdown-toggle"
    type="button"
    id="dropdownMenuButton"
    data-mdb-toggle="dropdown"
    aria-expanded="false"
  >
   {location}
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
  
 
  { listArrPlaces.map(function(element){
         return   <li><a class="dropdown-item"  onClick={(e) => {setlocation(element.name); setlocationVal(element.val); }  }  >{element.name}</a></li>
                  })}

   
  </ul>
</div>
  </div>




  <div class="col-6">
  <div class="dropdown" style={{width:"100%"}}>
  <button style={{width:"100%"}}
    class="btn btn-primary dropdown-toggle"
    type="button"
    id="dropdownMenuButton"
    data-mdb-toggle="dropdown"
    aria-expanded="false"
  >
  {category}
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">

  { listArrCategories.map(function(element){
         return   <li><a class="dropdown-item"  onClick={(e) => setcategory(element.name)}  >{element.name}</a></li>
                  })}

  
  </ul>
</div>
  </div>


  <div class="col-6">
  <div class="dropdown" style={{width:"100%"}}>
  <button style={{width:"100%"}}
    class="btn btn-primary dropdown-toggle"
    type="button"
    id="dropdownMenuButton"
    data-mdb-toggle="dropdown"
    aria-expanded="false"
  >
   {course}
  </button>
  <ul class="dropdown-menu dropdown-menu-float" aria-labelledby="dropdownMenuButton">
{ listArrCources.map(function(element){
         return   <li><a class="dropdown-item"  onClick={(e) => setcourse(element.name)}  >{element.name}</a></li>
                  })}
   
  
  </ul>
</div>
  </div>
 
</div>



</div>

<div class="row g-1 align-items-center" style={{paddingTop:"10px",paddingBottom:"40px"}}>
<div style={{display: "flex",justifyContent:"center",alignItems:"center"}} >
<div class="col-12">
    <div class="btn btn-warning"  style={{width:"250px"}}    onClick={(e) => makeSearch() } >הצג</div>
  </div>

  </div>
</div>


{isLoading && 
<div class="d-flex justify-content-center p-4" style={{minHeight:"400px"}}>
 <div style={{paddingTop:"25px"}}></div>
  <div class="spinner-border p-2" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
  <br></br> <br></br>
</div>}


<div class="row justify-content-end row-cols-2 row-cols-md-4 g-4">

  {(!isLoading)  && CoursesArr.map(function(element,index){
   if(  index >= Paging)
   return ;
  else
  return <a class="col"  href={`/crs/${element.ref}/${ageVal}/${locationVal}`} >
   <div class="card h-100">
     <img src={element.imageUrl} class="card-img-top" alt="Skyscrapers" style={{maxHeight:"205px"}}/>
 <span dir="rtl" style={{position:'absolute',top:"5px",right:"5px",backgroundColor:'rgba(0,0,0,0.7)',color:"white",borderRadius:"7px",paddingLeft:"8px",paddingRight:"8px"}}> { Canounter.find(el => el.ref === element.ref).count}  התאמות</span>
     {/*  { Canounter.find(el => el.ref === element.ref).count} */}
     <div class="card-body">
       <h5 class="card-title">{element.name}</h5>
      {false &&  <p class="card-text" dir="rtl">
       {element.desc}
       </p>}
     </div>
   
   </div>
 </a>
  })}
  


</div>




<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
        <button type="button" class="btn-close" data-mdb-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">...</div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-mdb-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div>




</div>
<br></br>

{(CoursesArr.length >=Paging) && 
<button type="button" class="btn btn-outline-dark btn-lg" onClick={(e) =>{ShowMore()}}>הצג עוד</button>}


<div class="container" style={{paddingTop:"100px",textAlign:"right",width:"80%",color: "black",paddingLeft:"10px",paddingRight:"10px"}} dir="rtl">
<h4 style={{color:"black"}}>קצת על האתר</h4>
<hr></hr>
<h5>
  אתר חוג מרכז חוגים ומסגרות איכותיות במגוון תחומים במיוחד לילדים ונוער
  <br>
  </br>
  החוגים נגישים בצורה פשוטה וניתן בקלות למצוא לפי התאמה גבוהה של גיל , איזור גיאוגרפי ופילוחים נוספים שמאפשרים חיפוש נח וקל
</h5>
<br>
  </br> 
<h5>
 האתר מתעדכן בתדירות יומית ושבועית וחוגים חדשים מתווספים כל הזמן, לעיתים אנו מציעים הטבות ייחודיות לגולשים ונרשמים דרכנו (שווה להתעדכן באתר)
</h5>
<br>
  </br>
<h5>
החוגים באתר מחולקים לפי קטגוריות ומידע כללי שמאפשר התרשמות מכל חוג בצורה מהירה
</h5>

<h5>
אנו בודקים את התכנים והמידע המפורסם באתר ומקפידים על עדכניות הנתונים, בנוסף לכל חוג המופיע בתוצאות קיים קישור לאתר הבית המקורי של מפעילי החוגים
<br></br>
<br>
  </br>

מאגר החוגים כולל את כל תחומי החוגים הקיימים לילדים ונוער החל מחוגי העשרה וידע כגון לימוד שפות,משחקי חשיבה שח מט ועד פעילות ספורטיבית כגון חוג טניס, שחיה כדורגל ועוד ועוד...
<br></br> <br></br>
לכם רק נותר למצוא את מה שהילדים אוהבים (:)
  <br></br>
<br>
  </br>
ניתן לפנות אלינו בכתובת המייל של האתר info@hoog.co.il 
<br></br>
או להשאיר הודעה ישירות באמצעות טופס <a style={{textDecoration:"underline"}}   href="/ContactUs" target='_blank'>יצירת הקשר</a> באתר

</h5>

</div>

<div class="container" style={{paddingTop:"100px",textAlign:"right",width:"80%"}}>

<h5 style={{padding:"25px",textAlign:"right"}} dir="rtl" >
פוסטים אחרונים
</h5>


<hr></hr>



<MDBRow className="row justify-content-end">

{ArrPOsts.map(function(element, index){
     return <MDBCol sm='6' className='p-2 text-right'>
        <MDBCard>
          <MDBCardBody>
          <a href={`/BlogItem/${element.ref}`} style={{textDecoration:"underline"}} >
            <MDBCardTitle style={{color: "var(--mdb-link-color)"}} dir="rtl">{element.title}</MDBCardTitle>
            </a>
            <a href={`/BlogItem/${element.ref}`} >
            <MDBCardText style={{margin:"0px"}}>
            {element.description}
            </MDBCardText>
            </a>
       

          </MDBCardBody>
        </MDBCard>
      </MDBCol>})}
  
     
    </MDBRow>

    </div>
   </div>
  );
}

export default MainPage;
