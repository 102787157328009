import { getFirestore, collection, addDoc,getDoc,doc, DocumentSnapshot,getDocs,serverTimestamp} from "firebase/firestore";


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBsb2ypUDRbSSZJg42efxUdynAJYRMT5SI",
  authDomain: "hoog-9090.firebaseapp.com",
  projectId: "hoog-9090",
  storageBucket: "hoog-9090.appspot.com",
  messagingSenderId: "351563549255",
  appId: "1:351563549255:web:73070520d24231c882bf62",
  measurementId: "G-HK52KMPR7Y"
};

// Initialize Firebase

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

var firstLoadArrCourses = [];
var firstLoadArrPOsts = [];


const analytics = getAnalytics(app);


const db = getFirestore(app);




export async function getCources_TEST() {
 
if(firstLoadArrCourses.length==0)
  firstLoadArrCourses =  await getDocs(collection(db, "courses"));

  return firstLoadArrCourses;

}




export async function getPosts_TEST() {
 

  if(firstLoadArrPOsts.length==0)
  firstLoadArrPOsts = await  getDocs(collection(db, "posts"));
 
   
 return firstLoadArrPOsts;

}





export default app;