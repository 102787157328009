import logo from './logo.svg';
import {React,useState} from 'react';
import './App.css';
import * as mdb from 'mdb-ui-kit'; // lib
import { Input } from 'mdb-ui-kit'; // module

import { MDBBadge, MDBBtn, MDBTable, MDBTableHead, MDBTableBody} from 'mdb-react-ui-kit';





function AboutUs() {

  const [isLoading, setisLoading] = useState(false);

  const [age, setage] = useState('התאמה לגיל');

  const [location, setlocation] = useState('איזור גיאוגרפי');
  const [course, setcourse] = useState('חוג ספציפי');  



  function makeSearch(){

    console.log('sdasd');
    setisLoading(true)
    setTimeout( function(){  setisLoading(false) }  ,500);
  }





  return (
    <div className="App">
      <header>

      <nav class="navbar fixed-top navbar-expand-lg navbar-light bg-white">
 
        <div class="container">
        
            <button class="navbar-toggler" type="button" data-mdb-toggle="collapse" data-mdb-target="#navbarSupportedContent1" aria-controls="navbarSupportedContent1" aria-expanded="false" aria-label="Toggle navigation">
                <i class="fas fa-bars"></i>
            </button>

          
            <div class="collapse navbar-collapse" id="navbarSupportedContent1">
              
              <a class="navbar-brand mt-2 mt-sm-0" href="https://Hoog.co.il/" >
              <img src="/android-chrome-192x192.png" height="20" alt="Hoog logo" loading="lazy" /><span style={{color:'#0080FF'}}>oog</span>
              
              </a>
              
              <ul class="navbar-nav me-auto mb-2 mb-lg-0" dir="rtl" >
                  <li class="nav-item active">
                      <a class="nav-link" href="/">בית</a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link" href="/aboutus/">אודות</a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link" href="/JoinForm">הצטרפות למאגר</a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link" href="/ContactUs">צור קשר</a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link" href="/Blog">בלוג</a>
                  </li>
              </ul>
              
          </div>
          
          <a  href="/" style={{textDecoration:"underline"}}><b>חזרה למאגר החוגים</b></a>
          
            
          
        </div>

    </nav>




<div id='my_parent' className="p-5 text-center bg-image" style={{backgroundImage: "url('https://cdn.pixabay.com/photo/2018/09/22/12/31/cat-3695213_1280.jpg')"}}>
  <div id='my_child' className="mask demo-content">
          <div className="d-flex justify-content-center align-items-center h-100" >
            <div className="text-white">
            <h1 className="mb-3"><br></br></h1>
              <h1 className="mb-3">קצת על האתר</h1>
     

            
            </div>
          </div>
  </div>
</div>


</header>
     
<div class="container" style={{paddingTop:"60px"}}>



{false && <div style={{display: "flex",justifyContent:"center",alignItems:"center"}} >
<div class="form-outline"  style={{width:"300px",textAlign:"center"}} >
  <input type="text" id="form12" class="form-control" />
  <label class="form-label" for="form12">חיפוש חופשי</label>
</div>
</div>}





{isLoading && 
<div class="d-flex justify-content-center p-4" style={{minHeight:"400px"}}>
 <div style={{paddingTop:"25px"}}></div>
  <div class="spinner-border p-2" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
  <br></br> <br></br>
</div>}







<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">פרטים נוספים</h5>
        <button type="button" class="btn-close" data-mdb-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">...</div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-mdb-dismiss="modal">סגור</button>
       
      </div>
    </div>
  </div>
</div>

<h5>אתר <b>"חוג"</b> הוקם מתוך רצון למאגר שקוף ונח של חוגים לילדים ונוער</h5>
</div>
<h6 className='container' style={{padding:"25px",lineHeight:"25px"}} dir="rtl">
בתור הורים לילדים, מצאנו לנכון לרכז מגוון מקורות ואתרים בתחום כדי להיחשף 
<br></br>
למגוון חוגים איכותיים בצורה יעילה
<br></br>
לאחר שהבנו שדי מייאש לחרוש אתרים בל סוף ורשתות חברתיות רק כדי למצוא לילדים שלנו חוג משתלם וטוב.

<br></br><br></br>
האתר מכיל חוגים שנבדקו ואותרו בצורה מקצועית למאגר שקוף , כולל ביקורות ודפים מקוריים 
<br></br>
של אנשי המקצוע כך שכהורים ניתן לקבל את כל המידע ולתאם שיחות עם מדריכים ולקבל הצעות מחיר משתלמות

</h6>
<br>
</br>
 </div>
   
  );
}

export default AboutUs;
