export var ArrAges = [];

var  itemAge = {name:"כיתות א'-ג'",val:1,grades:['ג','ב','א']};
ArrAges.push(itemAge);

itemAge = {name:"כיתות ד'-ו'",val:2,grades:['ו','ה','ד']};
ArrAges.push(itemAge);


itemAge = {name:"כיתות ז'-ט'",val:3,grades:['ט','ח','ז']};
ArrAges.push(itemAge);

