export const courcesList = [
    {
        "dateSend": {
            "seconds": 1702841227,
            "nanoseconds": 694000000
        },
        "retailer": "טקי-יוני",
        "phone": "03-7554100",
        "name": "מחשבים ותוכנה",
        "imageUrl": "https://cdn.pixabay.com/photo/2016/11/23/14/45/coding-1853305_1280.jpg",
        "categories": [
            "לימודי",
            "מקצועי",
            "חשיבה"
        ],
        "address": "",
        "price": "בהתאמה אישית",
        "ref": "computers",
        "siteUrl": "https://tekkieuni.co.il",
        "email": "",
        "places": [
            "אונליין"
        ],
        "totalMeetings": "לפי בחירה",
        "uid": "EHPO2SAy",
        "imageCourse": "https://cdn.pixabay.com/photo/2016/11/23/14/45/coding-1853305_1280.jpg",
        "ages": [
            "ג",
            "ד",
            "ה",
            "ו",
            "ז",
            "ח",
            "ט",
            "י",
            "יא",
            "יב"
        ],
        "desc": "לימוד מגוון תחומי פיתוח תוכנה ויישומי המחשב, משלב היכרות ועד מתקדמים",
        "days": [
            "לפי בחירה"
        ]
    },
    {
        "email": "",
        "desc": "חוגי ושיעורי בלט בהתאמה לגיל ולרמה בכל הארץ",
        "uid": "5EujLfn0",
        "dateSend": {
            "seconds": 1702841497,
            "nanoseconds": 912000000
        },
        "totalMeetings": "לפי בחירה",
        "price": "בהתאמה אישית",
        "places": [
            "חיפה"
        ],
        "categories": [
            "ריקוד",
            "פעילות"
        ],
        "address": "מרגלית 4",
        "name": "בלט",
        "ref": "ballet",
        "imageCourse": "https://cdn.pixabay.com/photo/2017/09/26/17/34/ballet-2789416_1280.jpg",
        "days": [
            "כל ימות השבוע"
        ],
        "retailer": "לואיזה",
        "imageUrl": "https://cdn.pixabay.com/photo/2017/09/26/17/34/ballet-2789416_1280.jpg",
        "phone": "072-3204088",
        "siteUrl": "",
        "ages": [
            "ג",
            "ד",
            "ה",
            "ו",
            "ז",
            "ח",
            "ט",
            "י",
            "יא",
            "יב"
        ]
    },
    {
        "days": [
            "לפי בחירה"
        ],
        "address": "מינהל קהילתי גינות העיר- בית יהודית, עמק רפאים",
        "name": "קפוארה",
        "phone": "050-8591685",
        "desc": "אמנות הריקוד הקצבי קפוארה בליווי מדריכים ומדריכות מתחום הלימוד, על פי קבוצות גיל והתאמה של יכולת פיזית",
        "uid": "XY0YMZmX",
        "retailer": "גאן מדריך קפוארה",
        "categories": [
            "ספורט",
            "פעילות"
        ],
        "dateSend": {
            "seconds": 1702810919,
            "nanoseconds": 898000000
        },
        "ages": [
            "ג",
            "ד",
            "ה",
            "ו",
            "ז",
            "ח",
            "ט",
            "י",
            "יא",
            "יב"
        ],
        "price": "190",
        "siteUrl": "https://www.ginothair.org.il/capoeira-class-for-children-in-jerusalem",
        "places": [
            "ירושלים"
        ],
        "imageCourse": "https://cdn.pixabay.com/photo/2018/03/04/00/40/young-3197021_1280.jpg",
        "imageUrl": "https://cdn.pixabay.com/photo/2018/03/04/00/40/young-3197021_1280.jpg",
        "ref": "capoeira",
        "totalMeetings": "לפי בחירה",
        "email": ""
    },
    {
        "desc": "לימוד אישי פרונטלי או אונליין של יסודות הגיטרה למתחילים ולמתקדמים",
        "siteUrl": "",
        "days": [
            "לפי בחירה"
        ],
        "totalMeetings": "לפי בחירה",
        "ages": [
            "לכל הגילאים"
        ],
        "imageCourse": "https://cdn.pixabay.com/photo/2015/05/07/11/02/guitar-756326_1280.jpg",
        "email": "",
        "dateSend": {
            "seconds": 1703231801,
            "nanoseconds": 102000000
        },
        "ref": "guitar",
        "phone": "050-9697054",
        "name": "גיטרה",
        "price": "בהתאמה אישית",
        "uid": "EwLE80SQ",
        "imageUrl": "https://cdn.pixabay.com/photo/2015/05/07/11/02/guitar-756326_1280.jpg",
        "places": [
            "גבעתיים"
        ],
        "address": "",
        "categories": [
            "מוסיקה"
        ],
        "retailer": "לורה בורלא - שיעורי גיטרה"
    },
    {
        "siteUrl": "https://www.e-motiondance.com/childrendance.html",
        "name": "בלט",
        "dateSend": {
            "seconds": 1703842875,
            "nanoseconds": 935000000
        },
        "ref": "ballet",
        "ages": [
            "א",
            "ב",
            "ג",
            "ד"
        ],
        "categories": [
            "ריקוד",
            "ספוקט",
            "פעילות"
        ],
        "price": "",
        "days": [
            "לפי בחירה"
        ],
        "imageCourse": "https://images7.webydo.com/91/9161962/3958%2f3B864189-06FC-FE7B-48DA-2B615606F223.png_400",
        "desc": "חוגי ושיעורי בלט בהתאמה לגיל ולרמה בכל הארץ",
        "phone": "054-5418819",
        "uid": "U40KVt9R",
        "email": "",
        "totalMeetings": "לפי בחירה",
        "imageUrl": "https://cdn.pixabay.com/photo/2017/09/26/17/34/ballet-2789416_1280.jpg",
        "address": "הירקון 39, בני ברק",
        "places": [
            "בני ברק"
        ],
        "retailer": "בית הספק לריקוד motion"
    },
    {
        "totalMeetings": "בהתאמה לפי קבוצה",
        "desc": "כדורסל לכל הגילאים ,בנים בנות ומגוון רמות בכל הארץ",
        "days": [
            "בהתאמה אישית"
        ],
        "address": "",
        "retailer": "כדורסל באהבה",
        "uid": "3EKHDe9T",
        "siteUrl": "https://www.basketball4u.co.il/",
        "ages": [
            "לכל הגילאים"
        ],
        "dateSend": {
            "seconds": 1703017272,
            "nanoseconds": 665000000
        },
        "imageUrl": "https://cdn.pixabay.com/photo/2019/03/10/15/48/basketball-4046437_1280.png",
        "price": "בהתאמה לפי קבוצה",
        "ref": "basketball",
        "imageCourse": "https://cdn.pixabay.com/photo/2019/03/10/15/48/basketball-4046437_1280.png",
        "phone": "052-6795667",
        "places": [
            "תל אביב",
            "רחובות",
            "ירושלים",
            "כפר סבא",
            "חיפה"
        ],
        "email": "service@basketball4u.co.il",
        "name": "כדורסל",
        "categories": [
            "ספורטיבי",
            "פעילות"
        ]
    },
    {
        "dateSend": {
            "seconds": 1702840950,
            "nanoseconds": 143000000
        },
        "places": [
            "כל הארץ"
        ],
        "imageCourse": "https://cdn.pixabay.com/photo/2018/07/08/08/45/abc-3523453_1280.jpg",
        "phone": "04-9509640",
        "price": "בהתאמה אישית",
        "totalMeetings": "לפי בחירה",
        "imageUrl": "https://cdn.pixabay.com/photo/2018/07/08/08/45/abc-3523453_1280.jpg",
        "days": [
            "לפי בחירה"
        ],
        "desc": "לימודי אנגלית לילדים בהתאמה אישית בהדרכת אנשי מקצוע מתחומי ההדרכה",
        "ref": "english",
        "siteUrl": "https://helendoron.co.il/",
        "retailer": "לימודי אנגלית לילדים ונוער בשיטת הלן דורון",
        "name": "אנגלית",
        "uid": "mv3YCnSV",
        "categories": [
            "שפות",
            "לימודי"
        ],
        "ages": [
            "ג",
            "ד",
            "ה",
            "ו",
            "ז",
            "ח",
            "ט",
            "י",
            "יא",
            "יב"
        ],
        "email": "israel@helendorongroup.com",
        "address": "כל הארץ"
    },
    {
        "imageUrl": "https://cdn.pixabay.com/photo/2016/05/16/21/07/football-1396740_1280.jpg",
        "uid": "Uk3MbtYT",
        "ages": [
            "כל הגילאים"
        ],
        "price": "לפי התאמה",
        "desc": "חוגי כדורגל לכל הגילאים לפי רמה ואזור בכל הארץ",
        "phone": "0504888722",
        "imageCourse": "https://cdn.pixabay.com/photo/2016/05/16/21/07/football-1396740_1280.jpg",
        "days": [
            "בהתאמה אישית"
        ],
        "name": "כדורגל",
        "dateSend": {
            "seconds": 1703016404,
            "nanoseconds": 724000000
        },
        "siteUrl": "https://soccer-training.co.il/",
        "places": [
            "חולון",
            "תל אביב",
            "ראשון לציון",
            "פתח תקווה"
        ],
        "ref": "soccer",
        "categories": [
            "פעילות",
            "ספורטיבי"
        ],
        "retailer": "דור העתיד - אימוני כדורגל",
        "totalMeetings": "לפי בחירה",
        "email": "dorheatid.drn@gmail.com",
        "address": "מוקדים ברחבי הארץ לפי עיר"
    },
    {
        "ref": "fashion",
        "dateSend": {
            "seconds": 1702743547,
            "nanoseconds": 997000000
        },
        "categories": [
            "אופנה",
            "כיף"
        ],
        "imageCourse": "https://cdn.pixabay.com/photo/2017/09/01/21/53/sunglasses-2705642_1280.jpg",
        "desc": "עיצוב אופנה לילדים, היכרות עם עולם האופנה, התנסות בתפירה וסגנונות בגדים",
        "price": "בהתאמה אישית",
        "imageUrl": "https://cdn.pixabay.com/photo/2017/09/01/21/53/sunglasses-2705642_1280.jpg",
        "retailer": "נעמי דוד - מעצבת אופנה",
        "email": "",
        "days": [
            "ראשון-חמישי"
        ],
        "name": "עיצוב אופנה",
        "siteUrl": "",
        "totalMeetings": "בהתאמה אישית",
        "ages": [
            "ג",
            "ד",
            "ה",
            "ו",
            "ז",
            "ח",
            "ט",
            "י",
            "יא",
            "יב"
        ],
        "address": "בן חיים 2",
        "places": [
            "רמת השרון"
        ],
        "phone": "072-3234116"
    },
    {
        "phone": "",
        "categories": [
            "חשיבה",
            "אסטרטגיה"
        ],
        "imageUrl": "https://cdn.pixabay.com/photo/2014/04/05/11/40/chess-316657_1280.jpg",
        "retailer": "chess",
        "desc": "משחק השחמט הידוע ,הדרכות לפי גילאים ואזור, היכרות עם חוקי המשחק ורמות מקצועיות כולל הכנה לתחרויות",
        "ages": [
            "ג",
            "ד",
            "ה",
            "ו",
            "ז",
            "ח",
            "ט",
            "י",
            "יא",
            "יב"
        ],
        "imageCourse": "https://cdn.pixabay.com/photo/2014/04/05/11/40/chess-316657_1280.jpg",
        "totalMeetings": "לפי בחירה",
        "ref": "chess",
        "uid": "nkNDYdhM",
        "name": "שח מט",
        "dateSend": {
            "seconds": 1702841965,
            "nanoseconds": 126000000
        },
        "places": [
            "אונליין"
        ],
        "email": "",
        "address": "",
        "days": [
            "לפי בחירה"
        ],
        "siteUrl": "https://www.chess.com/he/lessons",
        "price": "בהתאמה אישית"
    },
    {
        "email": "",
        "siteUrl": "",
        "imageUrl": "https://cdn.pixabay.com/photo/2015/05/07/11/02/guitar-756326_1280.jpg",
        "days": [
            "לפי בחירה"
        ],
        "address": "התותחנים 4",
        "uid": "9jqsgF1E",
        "name": "גיטרה",
        "categories": [
            "מוסיקה"
        ],
        "totalMeetings": "לפי בחירה",
        "retailer": "כפיר זריהן - מורה לגיטרה עד הבית",
        "ref": "guitar",
        "ages": [
            "לכל הגילאים"
        ],
        "imageCourse": "https://cdn.pixabay.com/photo/2015/05/07/11/02/guitar-756326_1280.jpg",
        "dateSend": {
            "seconds": 1703231986,
            "nanoseconds": 362000000
        },
        "phone": "050-7299538",
        "places": [
            "כפר סבא"
        ],
        "desc": "לימוד אישי פרונטלי או אונליין של יסודות הגיטרה למתחילים ולמתקדמים",
        "price": "125"
    },
    {
        "retailer": "דניאל שפירא",
        "categories": [
            "ספורט",
            "פעילות"
        ],
        "name": "טניס",
        "desc": "אימוני טניס וחוגים לכל הגילאים והרמות מאנשי מקצוע בתחום בעלי ניסיון",
        "siteUrl": "",
        "ref": "tenis",
        "price": "על בסיס מפגשים",
        "ages": [
            "ג",
            "ד",
            "ה",
            "ו",
            "ז",
            "ח",
            "ט",
            "י",
            "יא",
            "יב"
        ],
        "days": [
            "לפי בחירה"
        ],
        "email": "",
        "imageCourse": "https://cdn.pixabay.com/photo/2013/07/13/09/45/tennis-racket-155963_1280.png",
        "places": [
            "תל אביב",
            "חולון",
            "ראשון לציון"
        ],
        "phone": "054-4485034",
        "imageUrl": "https://cdn.pixabay.com/photo/2013/07/13/09/45/tennis-racket-155963_1280.png",
        "dateSend": {
            "seconds": 1702808278,
            "nanoseconds": 62000000
        },
        "address": "",
        "uid": "Dm9zJRfU",
        "totalMeetings": "8"
    },
    {
        "desc": "יסודות האלקטרוניקה והיכרות עם העולם הטכנולוגי לכל הגילאים",
        "name": "אלקטרוניקה",
        "address": "הרב קוק, 55 בני ברק",
        "imageCourse": "https://cdn.pixabay.com/photo/2014/09/20/13/52/board-453758_1280.jpg",
        "ref": "electronic",
        "uid": "taM0ucmR",
        "ages": [
            "לכל הגילאים"
        ],
        "siteUrl": "https://felita.co.il/he",
        "totalMeetings": "לפי בחירה",
        "places": [
            "רמת גן",
            "תל אביב",
            "בני ברק"
        ],
        "days": [
            "לפי בחירה"
        ],
        "price": "לפי בחירה",
        "phone": "054-4457665",
        "imageUrl": "https://cdn.pixabay.com/photo/2014/09/20/13/52/board-453758_1280.jpg",
        "categories": [
            "העשרה",
            "ידע",
            "לימודי",
            "מחשבתי"
        ],
        "dateSend": {
            "seconds": 1703235892,
            "nanoseconds": 20000000
        },
        "email": "felitacenter@gmail.com",
        "retailer": "Felita"
    },
    {
        "siteUrl": "https://englishwithasmile.org",
        "email": "",
        "phone": "073 - 7289118",
        "name": "אנגלית",
        "days": [
            "לפי בחירה"
        ],
        "desc": "לימודי אנגלית מעשית ופרקטית",
        "address": "תל אביב, הצבי 8",
        "retailer": "English with a Smile",
        "uid": "hRUBCFVg",
        "imageCourse": "https://cdn.pixabay.com/photo/2018/07/08/08/45/abc-3523453_1280.jpg",
        "ref": "english",
        "totalMeetings": "לפי בחירה",
        "price": "149",
        "ages": [
            "א",
            "ב",
            "ג",
            "ד",
            "ה",
            "ו",
            "ז",
            "ח",
            "ט",
            "י",
            "יא",
            "יב"
        ],
        "dateSend": {
            "seconds": 1702816864,
            "nanoseconds": 679000000
        },
        "imageUrl": "https://cdn.pixabay.com/photo/2018/07/08/08/45/abc-3523453_1280.jpg",
        "categories": [
            "שפות"
        ],
        "places": [
            "גבעתיים",
            "קריית אונו",
            "רמת גן",
            "תל אביב"
        ]
    },
    {
        "days": [
            "לפי בחירה"
        ],
        "categories": [
            "פעילות",
            "ספורט"
        ],
        "ages": [
            "ג-יב"
        ],
        "desc": "חוגי שחייה מקצועיית במגוון רמות לכל הגילאים על ידי מורים מוסמכים",
        "address": "פארק תעשייה משגב",
        "retailer": "pure water",
        "imageUrl": "https://cdn.pixabay.com/photo/2013/02/09/04/23/swimmers-79592_1280.jpg",
        "imageCourse": "https://cdn.pixabay.com/photo/2013/02/09/04/23/swimmers-79592_1280.jpg",
        "ref": "swim",
        "siteUrl": "",
        "totalMeetings": "לפי בחירה",
        "email": "info@helendorongroup.com",
        "places": [
            "ירושלים"
        ],
        "dateSend": {
            "seconds": 1702744095,
            "nanoseconds": 717000000
        },
        "price": "בהתאמה אישית",
        "phone": "04-9509640",
        "name": "שחייה"
    },
    {
        "phone": "073-3733999",
        "price": "בהתאם למסלול",
        "ages": [
            "כל הגילאים"
        ],
        "retailer": "ibrick-k",
        "name": "לגו",
        "uid": "K7NqC24F",
        "email": "info@ibrick-k.com",
        "imageUrl": "https://cdn.pixabay.com/photo/2017/06/30/15/53/lego-blocks-2458575_1280.jpg",
        "dateSend": {
            "seconds": 1703011336,
            "nanoseconds": 339000000
        },
        "categories": [
            "חשיבה"
        ],
        "totalMeetings": "לפי בחירה",
        "address": "שדרות הנשיא 77, חיפה",
        "ref": "lego",
        "imageCourse": "https://cdn.pixabay.com/photo/2017/06/30/15/53/lego-blocks-2458575_1280.jpg",
        "days": [
            "לפי בחירה"
        ],
        "siteUrl": "https://ibrick-k.com/category/%d7%94%d7%97%d7%95%d7%92%d7%99%d7%9d-%d7%a9%d7%9c%d7%a0%d7%95/",
        "places": [
            "אונליין"
        ],
        "desc": "פעילות של כיף בעולם הלגו, המשחק הפורסם בכל הרמות ולכל הגילאים לילדים שאוהבים להרכיב ולהתנסות"
    },
    {
        "imageCourse": "https://cdn.pixabay.com/photo/2013/07/12/19/18/magic-154526_1280.png",
        "dateSend": {
            "seconds": 1703842328,
            "nanoseconds": 570000000
        },
        "name": "קוסמות",
        "categories": [
            "חשיבה"
        ],
        "address": "גלר זאב 2",
        "imageUrl": "https://cdn.pixabay.com/photo/2013/07/12/19/18/magic-154526_1280.png",
        "desc": "לימוד קסמים וטכניקות ליצירת אשליות וזריזות ידיים",
        "totalMeetings": "לפי בחירה",
        "ref": "magic",
        "siteUrl": "https://www.cityedu.co.il/Hugim/default.aspx?siteId=3&customerid=269000#32",
        "places": [
            "כפר סבא"
        ],
        "retailer": "ביה\"ס ברנר",
        "days": [
            "ה"
        ],
        "ages": [
            "א",
            "ב",
            "ג",
            "ד"
        ],
        "price": "220",
        "email": "",
        "uid": "TGn4lzqw",
        "phone": "09-7640881"
    },
    {
        "email": "felitacenter@gmail.com",
        "name": "רובוטיקה",
        "categories": [
            "העשרה",
            "ידע",
            "לימודי",
            "מחשבתי",
            "טכנולוגי"
        ],
        "imageCourse": "https://cdn.pixabay.com/photo/2017/08/06/01/45/robot-2587571_1280.jpg",
        "siteUrl": "https://felita.co.il/he/hugim/hug-robotika",
        "dateSend": {
            "seconds": 1703236048,
            "nanoseconds": 693000000
        },
        "desc": "רובוטיקה לכל הגילאים ברחבי הארץ בליווי מקצועי של מדריכים בעלי רקע טכנולוגי",
        "days": [
            "לפי בחירה"
        ],
        "retailer": "Felita",
        "totalMeetings": "לפי בחירה",
        "phone": "054-4457665",
        "ref": "robotics",
        "imageUrl": "https://cdn.pixabay.com/photo/2017/08/06/01/45/robot-2587571_1280.jpg",
        "address": "הרב קוק, 55 בני ברק",
        "price": "לפי בחירה",
        "places": [
            "רמת גן",
            "תל אביב",
            "בני ברק"
        ],
        "ages": [
            "לכל הגילאים"
        ],
        "uid": "dPrG0UBg"
    },
    {
        "imageCourse": "https://cdn.pixabay.com/photo/2015/05/07/11/02/guitar-756326_1280.jpg",
        "name": "גיטרה",
        "imageUrl": "https://cdn.pixabay.com/photo/2015/05/07/11/02/guitar-756326_1280.jpg",
        "phone": "072-3938025",
        "uid": "ohjh23Hq",
        "totalMeetings": "לפי בחירה",
        "dateSend": {
            "seconds": 1703231878,
            "nanoseconds": 177000000
        },
        "categories": [
            "מוסיקה"
        ],
        "desc": "לימוד אישי פרונטלי או אונליין של יסודות הגיטרה למתחילים ולמתקדמים",
        "price": "בהתאמה אישית",
        "ages": [
            "לכל הגילאים"
        ],
        "address": "ברודצקי 27",
        "ref": "guitar",
        "email": "",
        "places": [
            "נתניה"
        ],
        "retailer": "מורה לגיטרה - רחל שרייבר",
        "days": [
            "לפי בחירה"
        ],
        "siteUrl": ""
    },
    {
        "price": "בהתאמה אישית",
        "email": "",
        "siteUrl": "",
        "address": "",
        "categories": [
            "מוסיקה"
        ],
        "places": [
            "תל אביב"
        ],
        "ages": [
            "לכל הגילאים"
        ],
        "uid": "7PXrsOFx",
        "desc": "לימוד אישי פרונטלי או אונליין של יסודות הגיטרה למתחילים ולמתקדמים",
        "imageCourse": "https://cdn.pixabay.com/photo/2015/05/07/11/02/guitar-756326_1280.jpg",
        "ref": "guitar",
        "totalMeetings": "לפי בחירה",
        "dateSend": {
            "seconds": 1703231720,
            "nanoseconds": 574000000
        },
        "phone": "073-7838296",
        "retailer": "לורן נוימן - מורה למוזיקה",
        "imageUrl": "https://cdn.pixabay.com/photo/2015/05/07/11/02/guitar-756326_1280.jpg",
        "days": [
            "לפי בחירה"
        ],
        "name": "גיטרה"
    },
    {
        "phone": "0723290672",
        "name": "פסנתר",
        "price": "150",
        "desc": "לימודי פנסתר על פי גיל ורמה ממיטב המורים והמסגרות בארץ",
        "siteUrl": "",
        "retailer": "לידיה אבסטדו",
        "ref": "piano",
        "address": "רמת השרון",
        "email": "",
        "imageUrl": "https://cdn.pixabay.com/photo/2014/06/04/17/53/piano-362251_1280.jpg",
        "totalMeetings": "לפי בחירה",
        "places": [
            "רמת השרון",
            "תל אביב",
            "הרצליה"
        ],
        "imageCourse": "https://cdn.pixabay.com/photo/2014/06/04/17/53/piano-362251_1280.jpg",
        "ages": [
            "ג",
            "ד",
            "ה",
            "ו",
            "ז",
            "ח",
            "ט",
            "י",
            "יא",
            "יב"
        ],
        "days": [
            "לפי בחירה"
        ],
        "dateSend": {
            "seconds": 1702743071,
            "nanoseconds": 523000000
        },
        "categories": [
            "מוסיקה",
            ""
        ]
    }
];