import logo from './logo.svg';
import {React,useState,useEffect} from 'react';
import './App.css';
import * as mdb from 'mdb-ui-kit'; // lib
import { Input } from 'mdb-ui-kit'; // module
import { useParams } from 'react-router';
import { MDBBadge, MDBBtn, MDBTable, MDBTableHead, MDBTableBody} from 'mdb-react-ui-kit';
import Image from 'react-bootstrap/Image';

import * as myConstClass from './consts.js';

import {MDBRow,MDBCol,MDBCard,MDBCardBody,MDBCardTitle,MDBCardText}  from 'mdb-react-ui-kit';

import {getCources_TEST,getPosts_TEST,filteredList_VISIO,listArrCategories,listArrPlaces,listCourcesSelection} from './firebase'

import {courcesList} from './dataConstantsCources.js'
import {postList} from './dataConstantsPosts.js'

function Blogs() {

  

  const [ArrPOsts, setArrPOsts] = useState(postList);

const params= useParams()


{/*   

useEffect(() => {


  const fetchPosts = async () => {

    var _arrposts = [];

    const result = await getPosts_TEST();
  
    result.forEach(quarySnapShot => {
      var element = quarySnapShot.data();

      _arrposts.push(element);

    });
  
   setArrPOsts(_arrposts);
   
  };
  fetchPosts();


  

}, []);

   */}



  return (
    <div className="App">
      <header>

      <nav class="navbar fixed-top navbar-expand-lg navbar-light bg-white">
 
        <div class="container">
        
            <button class="navbar-toggler" type="button" data-mdb-toggle="collapse" data-mdb-target="#navbarSupportedContent1" aria-controls="navbarSupportedContent1" aria-expanded="false" aria-label="Toggle navigation">
                <i class="fas fa-bars"></i>
            </button>

          
            <div class="collapse navbar-collapse" id="navbarSupportedContent1">
              
                <a class="navbar-brand mt-2 mt-sm-0" href="https://Hoog.co.il/" >
                 
                 
                <img src="/android-chrome-192x192.png" height="20" alt="Hoog logo" loading="lazy" /><span style={{color:'#0080FF'}}>oog</span>
                </a>
                
                <ul class="navbar-nav me-auto mb-2 mb-lg-0" dir="rtl" >
                    <li class="nav-item active">
                        <a class="nav-link" href="/">בית</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/aboutus/">אודות</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/JoinForm">הצטרפות למאגר</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/ContactUs">צור קשר</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="/Blog">בלוג</a>
                  </li>
                </ul>
                
            </div>
            
            
            <a  href="/" style={{textDecoration:"underline"}}><b>חזרה למאגר החוגים</b></a>
        </div>

    </nav>



   

</header>

<div class="container" style={{paddingTop:"100px",textAlign:"right",width:"80%"}}>
<h1>מאמרים שכתבנו וממש שווה לדעת</h1>


<hr></hr>



<h5 style={{padding:"25px",textAlign:"right"}} dir="rtl" >
פוסטים אחרונים
</h5>


<MDBRow className="row justify-content-end">

{ArrPOsts && ArrPOsts.map(function(element, index){
     return <MDBCol sm='6' className='p-2 text-right'>
        <MDBCard>
          <MDBCardBody>
            <MDBCardTitle>{element.title}</MDBCardTitle>
            <MDBCardText>
            {element.description}
            </MDBCardText>
            <MDBBtn href={`/BlogItem/${element.ref}`}    >קרא עוד</MDBBtn>

          </MDBCardBody>
        </MDBCard>
      </MDBCol>})};
  
     
    </MDBRow>




</div>


    </div>
   
  );
}

export default Blogs;
