import logo from './logo.svg';
import {React,useState,useEffect} from 'react';
import './App.css';
import * as mdb from 'mdb-ui-kit'; // lib
import { Input } from 'mdb-ui-kit'; // module
import { useParams } from 'react-router';
import { MDBBadge, MDBBtn, MDBTable, MDBTableHead, MDBTableBody} from 'mdb-react-ui-kit';
import Image from 'react-bootstrap/Image';

import * as myConstClass from './consts.js';

import {MDBRow,MDBCol,MDBCard,MDBCardBody,MDBCardTitle,MDBCardText}  from 'mdb-react-ui-kit';

import Autocomplete from 'react-autocomplete';

import {getCources_TEST,getPosts_TEST} from './firebase'

import {courcesList} from './dataConstantsCources.js'
import {postList} from './dataConstantsPosts.js'


import {

  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';




var arrAgesRange = myConstClass.ArrAges;


var FullCoursesArr = [];


function ItemPage() {

  var addItem = false;

  var filter1 = true;
  var filter2 = true;
  var filter3 = false;
  var filter4 = false;
  
  var INNER_filteredList = [];
  

const [value,setvalue ] = useState('');  






const [isLoading, setisLoading] = useState(false);
const [openModal, setopenModal] = useState(false);
const [basicModal, setBasicModal] = useState(false);

const toggleOpen = () => setBasicModal(!basicModal);



var filteredList_VISIO =[];
var  Tmp_listArrPlaces=[];
var arrcourcesALL = [];


function showItem(index)
{
  setmodalInfo(filteredList[index]);
}
  



const params= useParams();


console.log(params);





  arrcourcesALL = [];
  const result = courcesList;
 

  result.forEach(course => {

  var element = course;
  arrcourcesALL.push(element);

  // DROP DOWN PLACES
  if(element.places)
  {
  
      element.places.forEach(place => {
              const found = Tmp_listArrPlaces.some(el => el.name === place && el != 'כל הארץ' );
              if (!found && place != 'כל הארץ') 
              {
                Tmp_listArrPlaces.push({name:place,val:Tmp_listArrPlaces.length+1});
  
              }
            
          });
  }


});



var _BgImageUrl ='';
var _crs_name ='';
var _crs_desc ='';

var element =  arrcourcesALL.find(el =>  el.ref === params.ref );
if(element)
{

  _BgImageUrl = element.imageUrl;
  _crs_name = element.name;
  _crs_desc = element.desc;
 
}



var addItem = false;

var filter1 = true;
var filter2 = true;
var filter3 = false;




var INNER_filteredList = [];

var _age = 'התאמה לגיל';
var _ageVal = 0;
var _location= 'איזור גיאוגרפי';

if(!isNaN(params.place))
{

  if(params.place>0)
  var VI = Number(params.place);

 // console.log('FOUNT LOCATIN' + VI);
  var LocationItem =    Tmp_listArrPlaces.find(el => el.val === VI);

  if(LocationItem){
    _location = LocationItem.name;
  }
  
  

}


if(!isNaN(params.age))
{
  if(params.age>0)
  {
   
    var VI = Number(params.age);
  
    var ageItem =    arrAgesRange.find(el => el.val === VI);

    if(ageItem)
    {
      _age = ageItem.name;
      _ageVal= ageItem.val;
    
      console.log('FOUNT AGE');
    }

  }


}




arrcourcesALL.forEach(element => {
  
  addItem = false;
  var  found = false;
  

  filter1 = (params.ref == element.ref) ;


  filter2 = (element.places.some(el => el === _location || el === 'כל הארץ'  ) ||  _location == 'איזור גיאוגרפי' )
      

/// filter3 = AGE CHECK
  if(_ageVal>0)
  {
    filter3 = false;
      var ageItem = arrAgesRange.find(el => el.val === _ageVal);


for(var i=0;i<ageItem.grades.length;i++)
{
for(var j=0;j<element.ages.length;j++)
{
    if(element.ages[j] == ageItem.grades[i])
    {
      filter3 = true;
      break;
    }
       
}

if(filter3)
  break;

}
}
else
filter3 = true;



  if(filter1 && filter2 && filter3)
  {
    INNER_filteredList.push(element);


  }
     
  
  });
  


  const [filteredList,setfilteredList ] = useState(INNER_filteredList);  

  const [CoursesArr, setCoursesArr] = useState(arrcourcesALL); 
const [ArrPosts, setArrPOsts] = useState(postList); 




const [BgImageUrl,setBgImageUrl ] = useState(_BgImageUrl);  
const [crs_name,setcrs_name ] = useState(_crs_name);  
const [crs_desc,setcrs_desc ] = useState(_crs_desc);  


const [age, setage] = useState(_age);
const [ageVal, setageVal] = useState(_ageVal);

const [location, setlocation] = useState(_location);
const [modalInfo, setmodalInfo] = useState(0);
const [listArrPlaces, setlistArrPlaces] = useState(Tmp_listArrPlaces); 



{/*     
useEffect(() => {



  const fetchData = async () => {
  arrcourcesALL = [];
  const result = await getCources_TEST();
 

  result.forEach(quarySnapShot => {

  var element = quarySnapShot.data();
  arrcourcesALL.push(element);

  // DROP DOWN PLACES
  if(element.places)
  {
  
      element.places.forEach(place => {
              const found = Tmp_listArrPlaces.some(el => el.name === place && el != 'כל הארץ' );
              if (!found && place != 'כל הארץ') 
              {
                Tmp_listArrPlaces.push({name:place,val:Tmp_listArrPlaces.length+1});
  
              }
            
          });
  }


});


var element =  arrcourcesALL.find(el =>  el.ref === params.ref );
if(element)
{
 setBgImageUrl( element.imageUrl);
 setcrs_name(element.name);
 setcrs_desc( element.desc);
 
}



var addItem = false;

var filter1 = true;
var filter2 = true;
var filter3 = false;




var INNER_filteredList = [];
setlistArrPlaces(Tmp_listArrPlaces);


var _age = 'התאמה לגיל';
var _ageVal = 0;
var _location= 'איזור גיאוגרפי';

if(!isNaN(params.place))
{

  if(params.place>0)
  var VI = Number(params.place);

 // console.log('FOUNT LOCATIN' + VI);
  var LocationItem =    Tmp_listArrPlaces.find(el => el.val === VI);

  if(LocationItem){
    _location = LocationItem.name;
  }
  
  

}


if(!isNaN(params.age))
{
  if(params.age>0)
  {
   
    var VI = Number(params.age);
  
    var ageItem =    arrAgesRange.find(el => el.val === VI);

    if(ageItem)
    {
      _age = ageItem.name;
      _ageVal= ageItem.val;
    
      console.log('FOUNT AGE');
    }

  }


}



setage(_age);
setageVal(_ageVal);

  
setlocation(_location);

// setlocation(_location);
console.log(location);
arrcourcesALL.forEach(element => {
  
  addItem = false;
  var  found = false;
  

  filter1 = (params.ref == element.ref) ;


  filter2 = (element.places.some(el => el === _location || el === 'כל הארץ'  ) ||  _location == 'איזור גיאוגרפי' )
      

/// filter3 = AGE CHECK
  if(ageVal>0)
  {
    filter3 = false;
      var ageItem = arrAgesRange.find(el => el.val === ageVal);


for(var i=0;i<ageItem.grades.length;i++)
{
for(var j=0;j<element.ages.length;j++)
{
    if(element.ages[j] == ageItem.grades[i])
    {
      filter3 = true;
      break;
    }
       
}

if(filter3)
  break;

}
}
else
filter3 = true;



  if(filter1 && filter2 && filter3)
  {
    INNER_filteredList.push(element);


  }
     
  
  });
  

  setCoursesArr(arrcourcesALL);
  setisLoading(false);
  setfilteredList(INNER_filteredList); 
  
  fetchPosts();
  
//setlocation(_location);
setage(_age);

  };



  const fetchPosts = async () => {

    var arrposts = [];

    const result = await getPosts_TEST();
  
    result.forEach(quarySnapShot => {
      var element = quarySnapShot.data();

      arrposts.push(element);

    });
  
   setArrPOsts(arrposts);
   
  };
 

 
  fetchData();
 
}, []);

*/}



function makeSearch(){

      
  setisLoading(true);
  var addItem = false;

  var filter1 = true;
  var filter2 = true;
  var filter3 = false;
  

 

  var INNER_filteredList = [];
 
  CoursesArr.forEach(element => {
    
    addItem = false;
    var  found = false;
    
  
    filter1 = (params.ref == element.ref) ;


    filter2 = (element.places.some(el => el === location || el === 'כל הארץ') ||  location == 'איזור גיאוגרפי')
        

/// filter3 = AGE CHECK
    if(ageVal>0)
    {
      filter3 = false;
        var ageItem = arrAgesRange.find(el => el.val === ageVal);


for(var i=0;i<ageItem.grades.length;i++)
{
  for(var j=0;j<element.ages.length;j++)
  {
      if(element.ages[j] == ageItem.grades[i])
      {
        filter3 = true;
        break;
      }
         
  }

  if(filter3)
    break;

}
  }
  else
  filter3 = true;



    if(filter1 && filter2 && filter3)
    {
      INNER_filteredList.push(element);

  
    }
       
    
    });
    

    setfilteredList(INNER_filteredList); 

    setisLoading(false); 


}
  return (
    <div className="App">
      <header>

      <nav class="navbar fixed-top navbar-expand-lg navbar-light bg-white">
 
        <div class="container">
        
            <button class="navbar-toggler" type="button" data-mdb-toggle="collapse" data-mdb-target="#navbarSupportedContent1" aria-controls="navbarSupportedContent1" aria-expanded="false" aria-label="Toggle navigation">
                <i class="fas fa-bars"></i>
            </button>

          
            <div class="collapse navbar-collapse" id="navbarSupportedContent1">
              
                <a class="navbar-brand mt-2 mt-sm-0" href="https://Hoog.co.il/" >
                <img src="/android-chrome-192x192.png" height="20" alt="Hoog logo" loading="lazy" /><span style={{color:'#0080FF'}}>oog</span>
                </a>
                
                <ul class="navbar-nav me-auto mb-2 mb-lg-0" dir="rtl" >
                    <li class="nav-item active">
                        <a class="nav-link" href="/">בית</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/aboutus/">אודות</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/JoinForm">הצטרפות למאגר</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/ContactUs">צור קשר</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="/Blog">בלוג</a>
                  </li>
                </ul>
                
            </div>
            
            
            <a  href="/" style={{textDecoration:"underline"}}><b>חזרה למאגר החוגים</b></a>
        </div>

    </nav>



<div id='my_parent' className="p-5 text-center bg-image" style={{backgroundImage: "url('" + BgImageUrl+ "')"}}>
  <div id='my_child' className="mask demo-content" style={{height:"350px"}} >
          <div className="d-flex justify-content-center align-items-center"  style={{height:"350px"}} >
            <div className="text-white">
           <br></br>
              <h1 className="m-3" style={{paddingTop:"10px",fontWeight:"bold"}}>{crs_name}</h1>
              <h1>{crs_desc}</h1>
            
            </div>
          </div>
  </div>
</div>

</header>

<div class="container" style={{paddingTop:"1px"}}>
{false && <Autocomplete
  getItemValue={(item) => item.label}
  items={[
    { label: 'apple' },
    { label: 'banana' },
    { label: 'pear' }
  ]}
  renderItem={(item, isHighlighted) =>
    <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
      {item.label}
    </div>
  }
  value={value}
  onChange={(e) =>  setvalue( e.target.value) }
  onSelect={(val) => setvalue( val)}
/>}

<div className="d-flex justify-content-center align-items-center h-100">
            <div className="text-white">
      
   <div className='container' style={{padding:"20px"}} >
   <div className="row" style={{width:"100%"}}  >

        <div className="col-3">
       

        <div className="btn btn-warning"     onClick={(e) => makeSearch() } >הצג</div>
        </div>
       
        <div className="col-4">
        <div className="dropdown" style={{width:"110px"}}>
        <button style={{width:"110px"}}
        className="btn btn-primary dropdown-toggle"
        type="button"
        id="dropdownMenuButton"
        data-mdb-toggle="dropdown"
        aria-expanded="false"
        dir="rtl" >
         {age}
      </button>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
      
  { arrAgesRange.map(function(element){
     return  <li><a class="dropdown-item"   onClick={(e) => {setage(element.name);setageVal(element.val);}} dir="rtl"    >{element.name}</a></li>
     
                  })}

   
        </ul>
        </div>
        </div>
        <div className="col-1"></div>
        <div className="col-3">
        <div className="dropdown" >
        <button 
        className="btn btn-primary dropdown-toggle"
        type="button"
        id="dropdownMenuButton"
        data-mdb-toggle="dropdown"
        aria-expanded="false"
        >
        {location}
        </button>



        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton" >
     
  { listArrPlaces.map(function(element){
         return   <li style={{width:"110px"}}><a class="dropdown-item"  onClick={(e) => setlocation(element.name)   }  >{element.name}</a></li>
                  })}

        </ul>
        </div>
        </div>





                  </div>



        </div>


            
            </div>
          </div>

{(!isLoading)   && <h5 style={{paddingTop:"10px",textAlign:"right"}} dir="rtl">{filteredList.length} תוצאות</h5>}




{isLoading && 
<div class="d-flex justify-content-center p-4" style={{minHeight:"400px"}}>
 <div style={{paddingTop:"25px"}}></div>
  <div class="spinner-border p-2" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
  <br></br> <br></br>
</div>}

<MDBModal open={basicModal} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle> {modalInfo.retailer}</MDBModalTitle>
              <a className='btn-close' color='none'   onClick={(e) => setBasicModal(false) }></a>
            </MDBModalHeader>
            <MDBModalBody>
            <img src= {modalInfo.imageCourse} style={{maxWidth:"80%",maxHeight:"200px",padding:"20px"}}></img>
            <br></br> <br></br>
            {modalInfo.desc}  
            <br></br>
            {modalInfo.address}
            <br></br>   <br></br> 
              <b>{modalInfo.phone}</b>
              <br></br>
              {modalInfo.email}
             
              <br></br> <br></br>
             <a href= {modalInfo.siteUrl} target='_blank'>קישור לאתר</a>
              
             
              <br></br> <br></br>
            
              <br></br> 

            
             
            
            </MDBModalBody>

            <MDBModalFooter>
              <a color='secondary'   onClick={(e) => setBasicModal(false) }>
                סגור
              </a>
         
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>




{(!isLoading)   && 
<div className="row justify-content-end">
 {filteredList.map(function(element, index){
  return <div className="col-sm-6 p-2">
    <div className="card">
      <div className="card-body" style={{textAlign:"right"}} dir="rtl">
        <h5 className="card-title text-info">
        
       
        {element.retailer}
        </h5>
      <h6> {element.places.map(key => key + ' | ' )}</h6>
        <p className="card-text" >
      
        {element.desc}...
        </p>
        <Image src={element.imageCourse} roundedCircle  style={{maxHeight:"70px",maxWidth:"70px",margin:"15px",position:"absolute",top:"3px",left:"3px"}}  />
       

<div class="flex-container_course">
  
<div class="row_course">
  <h6 style={{padding:"10px",lineHeight:"25px"}} dir="rtl">
        
  {element.totalMeetings} מפגשים
          <br></br>
  {element.price} ש"ח
    </h6>

  </div>
  
  <div class="row_course">
  <div class="headerDivider"></div>

  </div>
  <div class="row_course">
  <h6 style={{padding:"10px",lineHeight:"25px"}} dir="rtl">
          כיתות:
          <br></br>
          ימים:
         
    </h6>

  </div>
  
  <div class="row_course">
    <h6 style={{padding:"10px",lineHeight:"25px"}} dir="rtl">
    {element.ages.map(key => key + ',' )}

          <br></br>
          {element.days.map(key => key + ',' )}
         
          
         
    </h6>

  </div>



 




</div>
    <div style={{textAlign:"left"}}>


          <a  class="btn btn-link p-0"    onClick={(e) =>   {showItem(index); setBasicModal(true)} } >
         הצג פרטים
          </a>

          </div>
      </div>
    </div>
  </div>})}
 
</div>}


</div>



<div class="container" style={{paddingTop:"100px",textAlign:"right",width:"80%"}}>
<h5 style={{padding:"25px",textAlign:"right"}} dir="rtl" > 
אולי יעניין אותך גם...
</h5>

<hr></hr>




<MDBRow className="row justify-content-end">

{ArrPosts.length >0 &&  ArrPosts.map(function(element, index){
     return <MDBCol sm='6' className='p-2 text-right'>
        <MDBCard>
          <MDBCardBody>
            <MDBCardTitle style={{color: "var(--mdb-link-color)"}} dir="rtl">{element.title}</MDBCardTitle>
            <MDBCardText>
            {element.description}
            </MDBCardText>
            <MDBBtn href={`/BlogItem/${element.ref}`}    >קרא עוד</MDBBtn>

          </MDBCardBody>
        </MDBCard>
      </MDBCol>})};
  
     
    </MDBRow>




</div>

    </div>
   
  );
}

export default ItemPage;
